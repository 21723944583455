var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.clonedTierData)?_c('v-dialog',{attrs:{"max-width":_vm.dialogWidth},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"accent","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v(" Tier Data ")])],1),_c('v-card-text',{staticClass:"px-4 py-8"},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.TierOptions,"label":"Engine Tier","persistent-placeholder":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.clonedTierData.tier),callback:function ($$v) {_vm.$set(_vm.clonedTierData, "tier", $$v)},expression:"clonedTierData.tier"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Tier Value","persistent-placeholder":"","hide-details":"","flat":"","outlined":"","dense":""},on:{"keypress":_vm.numericFilter},model:{value:(_vm.clonedTierData.tierValue),callback:function ($$v) {_vm.$set(_vm.clonedTierData, "tierValue", _vm._n($$v))},expression:"clonedTierData.tierValue"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"curves-data-table",attrs:{"headers":_vm.headers,"items":_vm.clonedTierData.curves},scopedSlots:_vm._u([{key:"item.power",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fromPower)+" - "+_vm._s(item.toPower)+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openTierDataCurveEditModal(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Config")])])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteTierData(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Config")])])]}},(_vm.isNoData)?{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-data-slot"},[_c('h6',{staticClass:"text-h6 mb-0"},[_vm._v("There are no curve segments created yet")])])]},proxy:true}:null],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mb-2 font-weight-bold primary--text",attrs:{"color":"neutralLighten100","small":"","dark":""},on:{"click":_vm.addNewCurve}},[_vm._v(" Add curve segment ")])],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end align-center pb-4 pt-1 px-4"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"accent"},on:{"click":_vm.saveChanges}},[_vm._v("Save")]),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"text":""},on:{"click":_vm.discardChanges}},[_vm._v("Cancel")])],1)],1),_c('ConfirmDialog',{ref:"confirmDelete"}),_c('SFOCPowerTierDataCurveEditDialong',{attrs:{"value":_vm.editCurveSegmentDialog,"config":_vm.config,"tier":_vm.clonedTierData,"curveData":_vm.curveSegmentToEdit,"max-width":'680px'},on:{"update:value":function($event){_vm.editCurveSegmentDialog=$event},"onSave":_vm.handleCurveSegmentSave}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }