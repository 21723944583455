import { CurveFunction } from "@/types/PowerSpeedCurveConfig";
export interface SFOCPowerCurveConfig {
  id: number;
  description: string;
  minSfoc: number;
  maxSfoc: number;
  minPower: number;
  maxPower: number;
  engineName: string;
  engineNameShort: string;
  vesselIds: number[];
  tiers: TierData[];
}

export interface TierData {
  id: number;
  configId: number;
  tier: EngineTier;
  tierValue: number;
  curves: SFOCPowerCurveData[];
}

export interface SFOCPowerCurveData {
  id: number;
  tierId: number;
  curveFunction: CurveFunction;
  fromPower: number;
  toPower: number;
  parameters: SFOCPowerCurveParameter[];
}

export interface SFOCPowerCurveParameter {
  id: number;
  curveDataId: number;
  constant: string;
  value: number;
}

export enum EngineTier {
  Tier2 = "Tier2",
  Tier3 = "Tier3",
}

export enum Constant {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}
