












































































































































import { Component, Prop, PropSync, Ref, Vue, Watch } from "vue-property-decorator";
import numericFilter from "Utilities/numericFilter";
//  types
import { CurveFunction } from "@/types/PowerSpeedCurveConfig";
import { SFOCPowerCurveConfig, TierData, EngineTier, SFOCPowerCurveData } from "@/types/SFOCPowerCurveConfig";
//  components
import SFOCPowerTierDataCurveEditDialong from "@/components/referencecurves/SFOCPowerTierDataCurveEditDialong.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    SFOCPowerTierDataCurveEditDialong,
    ConfirmDialog,
  },
})
export default class SFOCPowerTierDataEditDialog extends Vue {
  @PropSync("value", { required: true }) isOpen!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true }) config!: SFOCPowerCurveConfig;
  @Prop({ required: true }) tierData!: TierData;
  @Ref("confirmDelete") confirmDelete!: any;

  clonedTierData: TierData | null = null;
  editCurveSegmentDialog = false;
  curveSegmentToEdit: SFOCPowerCurveData | null = null;
  headers = [
    {
      text: "Curve",
      value: "curveFunction",
      class: "v-data-table-column-header",
      width: "45%",
      sortable: true,
    },
    {
      text: "Power Range",
      value: "power",
      class: "v-data-table-column-header",
      width: "45%",
    },
    {
      text: "Edit",
      value: "edit",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Delete",
      value: "delete",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
  ];

  get TierOptions(): string[] {
    return Object.keys(EngineTier);
  }

  get isNoData(): boolean {
    return Boolean(this.clonedTierData?.curves.length === 0);
  }

  @Watch("isOpen")
  handleCurveDataChange(): void {
    this.clonedTierData = JSON.parse(JSON.stringify(this.tierData));
  }

  numericFilter(event: any): number {
    return numericFilter(event);
  }

  openTierDataCurveEditModal(tierCurveData: SFOCPowerCurveData): void {
    this.editCurveSegmentDialog = true;
    this.curveSegmentToEdit = tierCurveData;
  }

  async deleteTierData(tierCurveData: SFOCPowerCurveData): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete Tier Curve", "Are you sure you want to delete this tier curve data?");
    if (confirmed) {
      if (!this.clonedTierData) return;
      const index = this.clonedTierData.curves.indexOf(tierCurveData);
      if (index > -1) this.clonedTierData.curves.splice(index, 1);
    }
  }

  addNewCurve(): void {
    const newCurve: SFOCPowerCurveData = {
      id: 0,
      tierId: this.clonedTierData?.id ?? 0,
      curveFunction: CurveFunction.Polynomial,
      fromPower: 0,
      toPower: 0,
      parameters: [],
    };
    this.clonedTierData?.curves.push(newCurve);
    this.openTierDataCurveEditModal(newCurve);
  }

  handleCurveSegmentSave(updatedCurveData: SFOCPowerCurveData): void {
    if (!this.clonedTierData) return;
    const index = this.clonedTierData.curves.findIndex(c => c.id === updatedCurveData.id);
    if (index > -1) {
      this.clonedTierData.curves.splice(index, 1, updatedCurveData);
      this.tierData.curves.splice(index, 1, updatedCurveData);
    }
  }

  saveChanges(): void {
    this.isOpen = false;
    this.$emit("onSave", this.clonedTierData);
  }

  discardChanges(): void {
    this.isOpen = false;
  }
}
