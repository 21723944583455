var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-container',[_c('h1',{staticClass:"d-flex pt-4 px-4"},[_vm._v(" SFOC Power "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2 font-weight-bold",attrs:{"small":"","color":"accent"},on:{"click":_vm.addConfig}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Config ")],1)],1),_c('v-text-field',{staticClass:"filter-input search-input px-4",attrs:{"label":"Search","append-icon":"mdi-magnify","persistent-placeholder":"","hide-details":"","flat":"","outlined":"","dense":""},on:{"input":_vm.doSearchDebounced}},[_c('template',{slot:"append"},[(_vm.debounceLoader)?_c('v-progress-circular',{staticClass:"circle-loader",attrs:{"indeterminate":"","color":"neutralDark75"}}):_c('v-icon',[_vm._v("mdi-magnify")])],1)],2),_c('v-data-table',{staticClass:"configs-data-table",attrs:{"headers":_vm.headers,"items":_vm.filtredConfigs,"loading":_vm.debounceLoader},scopedSlots:_vm._u([{key:"item.isValid",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(_vm.isConfigValid(item))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle-outline")])],1)]}}],null,true)},[(_vm.isConfigValid(item))?_c('span',[_vm._v("Config is valid")]):_c('span',[_vm._v("Config is NOT valid")])])]}},{key:"item.sfoc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minSfoc)+" - "+_vm._s(item.maxSfoc)+" ")]}},{key:"item.power",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minPower)+" - "+_vm._s(item.maxPower)+" ")]}},{key:"item.vessels",fn:function(ref){
var item = ref.item;
return _vm._l((item.vesselIds),function(vesselId,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getVesselName(vesselId))+"; ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getVesselName(vesselId)))])])})}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openConfigEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Config")])])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteConfig(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Config")])])]}},(_vm.isNoData)?{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-data-slot"},[_c('h6',{staticClass:"text-h6"},[_vm._v("There are no configs created yet")])])]},proxy:true}:null],null,true)}),_c('SFOCPowerConfigEditDialog',{attrs:{"value":_vm.editDialog,"config":_vm.configToEdit,"allVessels":_vm.allVessels,"currentCompanyVessels":_vm.currentCompanyVessels,"max-width":'1050px'},on:{"update:value":function($event){_vm.editDialog=$event},"onConfigSave":_vm.fetchConfigs}}),_c('ConfirmDialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }