











































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import _ from "underscore";
// components
import SFOCPowerConfigEditDialog from "@/components/referencecurves/SFOCPowerConfigEditDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
//  clients
import SFOCPowerCurveClient from "Clients/sfoc-power-curve-client";
import VesselsClient from "Clients/vessels-client";
// types
import { SFOCPowerCurveConfig, SFOCPowerCurveData, TierData } from "@/types/SFOCPowerCurveConfig";
import { Vessel } from "@/types/Vessel";
// modules
import UserModule from "@/store/clients/User.module";

const User = getModule(UserModule, store);

@Component({
  components: {
    SFOCPowerConfigEditDialog,
    ConfirmDialog,
  },
})
export default class SFOC extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;

  editDialog = false;
  configToEdit: SFOCPowerCurveConfig | null = null;
  configs: SFOCPowerCurveConfig[] = [];
  allVessels: Vessel[] = [];
  currentCompanyVessels: Vessel[] = [];
  doSearchDebounced = _.debounce(this.updateSearchQueryInput, 1000);
  searchQuery: string | null = null;
  debounceLoader = false;
  headers = [
    {
      text: "Status",
      value: "isValid",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Id",
      value: "id",
      class: "v-data-table-column-header",
      width: "5%",
    },
    {
      text: "Description",
      value: "description",
      class: "v-data-table-column-header",
      width: "20%",
    },
    {
      text: "Vessels",
      value: "vessels",
      class: "v-data-table-column-header",
      width: "60%",
    },
    {
      text: "Edit",
      value: "edit",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Delete",
      value: "delete",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
  ];

  get isAdmin(): boolean {
    return User.isAdmin;
  }

  get isNoData(): boolean {
    return Boolean(this.configs.length === 0);
  }

  get filtredConfigs(): SFOCPowerCurveConfig[] {
    if (!this.searchQuery) return this.configs;

    //  if searchable item is a vessel < >
    let vesselInSearch: Vessel | null = null;
    const isVesselInSearch = this.allVessels.some(v => {
      if (v.name.toLowerCase().includes((this.searchQuery as string).toLowerCase())) {
        vesselInSearch = v;
        return true;
      }
    });
    if (isVesselInSearch) {
      return this.configs.filter(config => {
        const hasSearchQuery = config.vesselIds.filter(id => {
          if (id === vesselInSearch?.id) {
            return id;
          }
        });
        return hasSearchQuery.length ? config : false;
      });
      //  if searchable item is a vessel </>
    } else {
      return this.configs.filter(c => c.description && c.description.toLowerCase().includes((this.searchQuery as string).toLowerCase()));
    }
  }

  async fetchConfigs(): Promise<void> {
    this.configs = await SFOCPowerCurveClient.getAllConfigs();
  }

  updateSearchQueryInput(newValue: string): void {
    this.debounceLoader = true;
    this.searchQuery = newValue;
    setTimeout(() => (this.debounceLoader = false), 500);
  }

  async addConfig(): Promise<void> {
    await SFOCPowerCurveClient.postEmptyConfig();
    this.configs = await SFOCPowerCurveClient.getAllConfigs();
    this.openConfigEditDialog(this.configs[this.configs.length - 1]);
  }

  async deleteConfig(config: SFOCPowerCurveConfig): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete config", "Are you sure you want to delete this config?");
    if (confirmed) {
      await SFOCPowerCurveClient.deleteConfig(config.id);
      this.configs = await SFOCPowerCurveClient.getAllConfigs();
    }
  }

  openConfigEditDialog(config: SFOCPowerCurveConfig): void {
    this.editDialog = true;
    this.configToEdit = config;
  }

  getVesselName(vesselId: number): string | undefined {
    return this.allVessels.find(v => v.id === vesselId)?.name;
  }

  isValidCurveData(curveData: SFOCPowerCurveData): boolean {
    const minNumberOfConstants = curveData.curveFunction === "Rational" ? 4 : 3;
    const numberOfConstants = curveData.parameters.length;
    return numberOfConstants >= minNumberOfConstants;
  }

  hasMaxOneOfEachTier(tiers: TierData[]): boolean {
    const tierCount: { [key: string]: number } = {};
    for (const data of tiers) {
      const tier = data.tier;
      if (tierCount[tier]) {
        return false;
      }
      tierCount[tier] = 1;
    }
    return true;
  }

  isConfigValid(config: SFOCPowerCurveConfig): boolean {
    const hasAtLeastOneTier = config.tiers.length > 0;
    const hasMaxOneOfeachTier = config.tiers.length > 0 && this.hasMaxOneOfEachTier(config.tiers);
    const hasAtLeastOneCurve = config.tiers.length > 0 && config.tiers.every(t => t.curves.length > 0);
    const areCurvesValid = config.tiers.length > 0 && config.tiers.every(t => t.curves.every(c => this.isValidCurveData(c)));
    const areAllPowerSet = config.tiers.length > 0 && config.tiers.every(t => t.curves.every(c => c.toPower !== 0));
    const areAllConstantsSet = config.tiers.length > 0 && config.tiers.every(t => t.curves.every(c => c.parameters.every(constant => constant.value !== 0)));
    return hasAtLeastOneTier && hasMaxOneOfeachTier && hasAtLeastOneCurve && areCurvesValid && areAllPowerSet && areAllConstantsSet;
  }

  async created(): Promise<void> {
    if (!this.isAdmin) this.$router.push("/");
    this.fetchConfigs();
    const ignoreCompany = true;
    this.allVessels = await VesselsClient.getVessels(ignoreCompany);
  }
}
