import net from "App/net";

class SfocPowerCurveClient {
  constructor() {
  }

  getAllConfigs() {
    return net.get("/ReferenceCurves/SfocPower/Configs");
  }

  postEmptyConfig() {
    return net.post("/ReferenceCurves/SfocPower/Configs");
  }

  postConfig(config) {
    return net.post("/ReferenceCurves/SfocPower/Configs", config);
  }

  updateConfig(config) {
    return net.put("/ReferenceCurves/SfocPower/Configs", config);
  }

  deleteConfig(id) {
    return net.del(`/ReferenceCurves/SfocPower/Configs/${id}`);
  }
}


export default new SfocPowerCurveClient();
